import {inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {I18N} from 'aurelia-i18n';
import {ModuleConfigClient} from "../api/module-config-client";
import {LocaleService} from "../i18n/locale-service";

@inject(Client, I18N, ModuleConfigClient, LocaleService)
export class ModelLabelService {
    constructor(client, i18n, moduleConfigClient, localeService) {
        this.client = client;
        this.i18n = i18n;
        this.moduleConfigClient = moduleConfigClient;
        this.localeService = localeService;
    }

    getReferenceLinks(reference, multiModel, labelProperty) {
        //If we have this parameters set, we can directly display references

        if (reference.id && reference.modelId && reference.objectLabel && (reference.displayView || false === reference.displayView) && !labelProperty) {

            const link = {label: reference.objectLabel};

            if ("string" === typeof reference.displayView) {
                const viewParts = reference.displayView.split('/');
                link.linkParams = {
                    moduleId: viewParts[0],
                    viewId: viewParts[1],
                    id: reference.id
                };
            }

            if (reference.longLabel) {
                link.longLabel = reference.longLabel;
            }

            return Promise.resolve(link);
        }

        const modelId = reference.modelId;
        //If created is not set we load entity
        const refPromise = reference.createdAt ? Promise.resolve(reference) : this.client.get(modelId + '/' + reference.id, 60);
        const configPromise = this.moduleConfigClient.getModel(modelId);

        return Promise.all([configPromise, refPromise]).then((array) => {

            const model = array[0];
            const reference = array[1];
            let label = null;
            let longLabel = null;

            if (labelProperty) {
                label = reference[labelProperty];
            }

            if ((!label || label === '') && model.shortLabelProperty && reference[model.shortLabelProperty]) {
                label = reference[model.shortLabelProperty];
            }

            if (model.labelProperty && reference[model.labelProperty]) {
                longLabel = reference[model.labelProperty];
            }

            if (label && label[this.localeService.contentLocale]) {
                label = label[this.localeService.contentLocale];
            }

            if (longLabel && longLabel[this.localeService.contentLocale]) {
                longLabel = longLabel[this.localeService.contentLocale];
            }

            if ((!label || label === '') && longLabel) {
                label = longLabel;
                longLabel = null;
            }

            if (!reference.objectLabel && !label) {
                if (!model.label) {
                    return null;
                } else {
                    label = ' ';
                }
            }

            label = String(label);

            const link = {};

            if (reference.objectLabel) {
                link.label = reference.objectLabel;
            } else if (multiModel && !model.defaultInterfaceImplementation) {
                link.label = label + ' (' + this.i18n.tr(model.label) + ')';
            } else {
                link.label = label;
            }

            link.longLabel = longLabel;
            link.reference = reference;

            if (model.displayView) {
                const viewParts = model.displayView.split('/');

                link.linkParams = {
                    moduleId: viewParts[0],
                    viewId: viewParts[1],
                    id: reference.id
                };
            }

            return link;
        });
    }
}
