import { useCallback, useEffect, useState } from 'react';
import { I18N } from 'aurelia-i18n';
import { Container } from 'aurelia-framework';
import { LocaleService } from '../i18n/locale-service';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserContentLocale, setUserContentLocale } from '../store/store-slices/userSettingsSlice';
let i18nInstance = Container.instance.get(I18N);
let Locale = Container.instance.get(LocaleService);

export const getTranslation = (key, options) => {
  if (!i18nInstance) {
    console.warn('I18N instance not set');
    return key;
  }
  return i18nInstance.tr(key, options);
};

export const getLocale = () => {
  if (!Locale) {
    console.warn('Locale instance not set');
    return {};
  }
  return Locale;
};

export function useAureliaI18n() {
  const i18n = I18N.instance;
  const [contentLocale, setContentLocale] = useState(getLocale().contentLocale);
  const dispatch = useDispatch();

  const t = useCallback(
    (key, options) => {
      return getTranslation(key, options);
    },
    [i18n]
  );

  useEffect(() => {
    console.debug('Locale: ', contentLocale);
    if (contentLocale !== getLocale().contentLocale) {
      getLocale().setContentLocale(contentLocale);
      dispatch(setUserContentLocale(contentLocale));
    }else{
      dispatch(setUserContentLocale(contentLocale));
    }
  }, [contentLocale]);


  return {
    t,
    locale: {
      instance: getLocale(),
      contentLocale,
      setContentLocale,
      allLocales: getLocale()?.contentLocales,
    },
  };
}
