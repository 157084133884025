import { useCallback } from 'react';
import { Container } from 'aurelia-framework';
import { UserClient } from '../api/user-client';

let UserClientInstance = Container.instance.get(UserClient);

export const getUserClient = () => {
  if (!UserClientInstance) {
    console.warn('User Client instance not set');
    return false;
  }
  return UserClientInstance;
};

export function useUserClient() {
  const user = UserClientInstance?.getUser();

  const hasRole = (roleOrRoles) => {
    if (!user?.roles) {
      return false;
    }

    if (
      !roleOrRoles ||
      !roleOrRoles.length ||
      user.roles.includes('ROLE_SUPER_ADMIN') ||
      user.roles.includes('ROLE_ADMIN')
    ) {
      return true;
    }
    ('string' === typeof roleOrRoles ? [roleOrRoles] : roleOrRoles).forEach(
      (role) => {
        if (user.roles.includes(role)) {
          return true;
        }
      }
    );

    return false;
  };

  return [UserClientInstance, hasRole, user];
}
